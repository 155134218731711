$(function () {
    $("#product-category").change(function () {
        let category = $(this).val();

        if(category) {
            ajax("product-attributes", {
                route : {
                    slug: category
                }
            }, function (response) {
                if (response.status == "success") {

                    let attributes = new Map(Object.entries(response.attributes));

                    $(".product-attribute").each(function () {

                        let attribute = $(this);

                        attribute.hide();

                        attribute.find(".product-attribute-value").each(function () {
                            $(this).hide();
                            $(this).findSelf("input").prop("disabled", true);
                        });

                        let name = attribute.data("product-name");

                        if (attributes.has(name)) {
                            let attributeValues = attributes.get(name);
                            attribute.show();

                            $.each(attributeValues, function (key, item) {
                                let attributeValue = $(".product-attribute-value[id=product-" + name + "-" + item + "]");
                                attributeValue.show();
                                attributeValue.findSelf("input").prop("disabled", false);
                            });
                        } else {
                            if (attribute.hasClass("product-attribute-visible")) {
                                attribute.show();
                            }
                        }
                    });
                }
            });
        }
    });

    // After load we immediatly trigger a changce event so that the event listener can run, and hide all the non desired elements
    $("#product-category").trigger("change");
});
